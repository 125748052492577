.ant-spin-nested-loading {
  width: 100% !important;
}
.ant-menu-overflow {
  overflow: hidden !important;
}
.table-title {
  font-size: 24px;
  line-height: 1.2;
}
.ant-form-item {
  width: 95% !important;
}
.form-label-custom {
  margin-bottom: 4px;
  font-weight: 300;
}
.address-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}
.address-container {
  position: relative;
  width: 100%;
}
.address-collapse-block {
  margin-top: 15px;
}
.address-collapse-block .ant-collapse-header {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}
