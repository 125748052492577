.removePadding {
  background-color: transparent;
}
.removePadding .ant-card-body,
.removePadding .ant-card-head {
  padding: 0 !important;
}
.extraUsers .ant-card-extra {
  padding: 0 !important;
  width: -webkit-fill-available !important;
  margin-top: -1px !important;
}
.extraUsers .ant-card-head {
  margin-top: 2px;
}
.usageElectron button {
  margin: 0 5px;
}
.detailTableSmall {
  zoom: 0.9;
}
.scrollYTable .ant-table-small {
  border: 0px;
}
.scrollYTable .ant-table-small .ant-table-body {
  margin: 0;
}
.scrollYTable .ant-table-small .ant-table-thead {
  background-color: #fafafa;
}
.scrollYTable .ant-table-small .ant-table-header {
  background-color: #fafafa;
}
.scrollYTable .ant-table-fixed .ant-table-thead {
  background-color: #fafafa;
}
.button-link {
  background: none !important;
  border: none;
  padding: 0!important;
  font-size: 14px !important;
  color: #1890ff;
  cursor: pointer;
}
.grad-text {
  background: linear-gradient(to right, red, orange, green, cyan, blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
}
