.nocardPadding .ant-modal-body {
  padding: 0;
}
.nocardPadding .ant-card-bordered {
  border: 0;
}
.payment__img {
  max-width: 25px;
  margin-right: 5px;
}
