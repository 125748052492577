.avg-charging-card {
  margin: auto;
  padding-right: 0px;
  width: fit-content !important;
  border: 2px solid #00A2FF;
  border-radius: 5px;
}
.avg-charging-card .ant-card-body {
  padding-right: 0px !important;
  width: fit-content !important;
}
.avg-charging-card .ant-statistic {
  width: fit-content !important;
  margin-right: 60px !important;
}
.avg-charging-card .ant-statistic p {
  margin-bottom: 2px !important;
  margin-top: 6px !important;
}
.avg-charging-card .ant-statistic .ant-statistic-content {
  margin-bottom: 16px;
}
.super-users-progress {
  margin: auto;
  padding: 0px 0px 24px 0px;
}
.super-users-progress .super-users-text {
  color: #FF2D55;
}
.super-users-progress .super-users-text svg {
  position: relative;
  top: -4px;
  left: 4px;
}
.trends-reports .ant-alert-info,
.survey-reports .ant-alert-info {
  width: 100% !important;
}
.trends-reports .ant-row,
.survey-reports .ant-row {
  width: 100% !important;
}
