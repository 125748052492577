.requestPayment {
  display: flex;
  width: 100%;
}
.requestPayment__box {
  display: flex;
  cursor: pointer;
}
.requestPayment__label {
  margin-right: 20px;
}
.requestPayment__label + label {
  margin-left: 30px;
}
.requestPayment__help {
  display: block;
  line-height: 1;
  color: #ACB7BF;
  font-size: 12px;
  margin-top: -5px;
}
.requestPayment__help--red {
  color: #EB4A68;
}
