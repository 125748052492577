.StatisticsScreen .textAlignRight {
  text-align: right;
}
.StatisticsScreen .tableTop {
  margin-top: 20px;
}
.StatisticsScreen .getPdf {
  margin-left: 12px;
}
.StatisticsScreen .recharts-legend-wrapper {
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}
.StatisticsScreen .date-container {
  display: flex;
  width: fit-content;
  margin-left: auto;
  align-items: flex-end;
}
.StatisticsScreen .date-btns-container {
  margin: 4px 0px;
}
.StatisticsScreen .date-adds-container {
  margin: 4px 0px;
}
