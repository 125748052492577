.modalWindow__container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modalWindow__card {
  display: block;
  min-height: 400px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  width: 1100px;
  overflow: scroll;
  max-height: 90vh;
}
.vertical-center-modal .ant-modal {
  top: 0;
}
