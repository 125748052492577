.react-trello-board {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  user-select: none;
}
.react-trello-board * {
  user-select: none;
}
.tickets-filter {
  display: inline-block;
  margin: 10px 0px 16px 0px;
}
.tickets-filter button {
  margin: 0 5px;
}
.tickets-filter :first-child {
  margin-left: 0px !important;
}
