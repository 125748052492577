.custom-tooltip-survey-age-gender {
  background: #ffffff;
  padding: 15px 40px 15px 20px;
  border: solid 1px rgba(0, 0, 0, 0.65);
  border-radius: 6px;
}
.custom-tooltip-survey-age-gender .inside-tooltip-row {
  margin-bottom: 3px;
  padding-right: 50px;
  text-align: left;
}
.custom-tooltip-survey-age-gender .inside-tooltip-row .bold {
  font-weight: 500;
}
.override-recharts-legend {
  margin: 24px 0px;
}
.override-recharts-legend .recharts-legend-wrapper {
  position: inherit !important;
}
.nps-part {
  margin: 24px 0px;
}
.nps-part .formula {
  font-size: 46px;
  font-weight: 100;
  margin: 18px auto;
  width: fit-content;
}
.nps-part .formula .dashboard-icon {
  font-size: 0.6em;
  margin-right: 4px;
}
.nps-part .formula .name {
  font-weight: 700;
  color: #3d3d3d;
}
.nps-part .formula .emotion-icon {
  margin-left: 4px;
}
.nps-part .nps-explain .header {
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 24px;
  font-weight: 700;
}
.nps-part .nps-explain .nps-explanation-row {
  width: 80% !important;
  margin: 12px auto 36px auto;
  display: flex;
  flex-direction: row;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container {
  width: 25%;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container.w-50 {
  width: 50%;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container.w-15 {
  width: 15%;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container.w-20 {
  width: 20%;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container .title {
  font-size: 20px;
  font-variant: all-small-caps;
  font-weight: 500;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container .value {
  font-size: 14px;
  font-variant: all-small-caps;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container .color-line {
  height: 26px;
  width: 100%;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container .color-line p {
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0px 0px 0px 8px;
  width: fit-content;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container .color-line.red {
  background: #ff3b30;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container .color-line.light-green {
  background: #4cd964;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container .color-line.dark-green {
  background: #1EAA36;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container .color-line.yellow {
  background: #ff9500;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container .color-line .text-outside-right {
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.65) !important;
  position: relative;
  right: -38px;
}
.nps-part .nps-explain .nps-explanation-row .nps-explanation-container .color-line .text-outside-left {
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.65) !important;
  position: relative;
  left: -45px;
}
