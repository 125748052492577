.header-logo {
  float: left;
  margin: 19px 28px 16px 0;
}
.header-logo-img {
  display: block;
  max-height: 25px;
}
.layout {
  min-height: 100vh;
}
.fill-bg-table .ant-table-body {
  background: #ffffff;
}
