.modal-buttons-center .ant-modal-confirm-btns {
  float: none;
  direction: rtl;
  display: flex;
  justify-content: center;
}
.modal-buttons-center .ant-modal-confirm-btns button {
  margin-left: 38px;
}
.modal-buttons-center .ant-modal-confirm-btns button:last-child:first-child {
  margin-left: 0;
}
@media (max-width: 767px) {
  .modal-buttons-center .ant-modal-confirm-btns button {
    margin-left: 16px;
  }
}
