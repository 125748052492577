.circle-data-container {
  width: 100%;
}
.circle-data-container .label {
  color: #6B6F6E;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}
.circle-data-container .circle-wrapper {
  overflow: hidden;
  position: relative;
  margin: auto;
  width: 85%;
  padding-bottom: 85%;
}
.circle-data-container .red {
  background: #ff3b30 !important;
}
.circle-data-container .green {
  background: #4cd964 !important;
}
.circle-data-container .circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 95%;
  border-radius: 50%;
  background: #5856d6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-data-container .circle .text {
  color: white;
  font-size: 3.5em;
  width: fit-content;
  max-width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px;
}
.circle-data-container .inner-pie-label {
  font-size: 2em;
  font-weight: 600;
}
.margin-24 {
  margin-left: 24px;
}
.retention-wheel-container {
  margin: 12px 0px;
  width: 400px;
  height: 400px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./components/images/wheel-3-sections.svg');
  display: flex;
  position: relative;
  color: #ECECEC;
  font-weight: 600;
  font-size: 18px;
}
.retention-wheel-container .center-descr {
  width: 50%;
  height: 50%;
  color: #525252 !important;
  font-weight: 600;
  font-size: 36px;
  display: flex;
  position: absolute;
  top: 25%;
  left: 25%;
}
.retention-wheel-container .center-descr span {
  margin: auto;
}
.retention-wheel-container .open-descr,
.retention-wheel-container .reg-descr,
.retention-wheel-container .checkout-descr,
.retention-wheel-container .return-descr {
  position: absolute;
  font-size: 32px;
  color: #FFFFFF;
  -webkit-text-stroke: 0.5px black;
  font-weight: 600;
}
.retention-wheel-container .reg-descr {
  left: 59%;
  top: 14%;
}
.retention-wheel-container .checkout-descr {
  left: 56%;
  top: 77%;
}
.retention-wheel-container .return-descr {
  left: 6%;
  top: 40%;
}
.retention-wheel-container-legend-web {
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.retention-wheel-container-legend-web ul {
  font-size: 18px;
}
.top-margin {
  margin-top: 24px;
}
.row-label-btns {
  color: #6B6F6E;
  font-size: 18px;
  font-weight: 600;
}
.row-label-btns button {
  margin-left: 12px !important;
}
.unselectable {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */
}
