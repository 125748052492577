.reports .pink {
  color: #EF5FA7;
}
.reports .blue {
  color: #00A2FF;
}
.reports label {
  white-space: nowrap;
}
@media only screen and (max-width: 576px) {
  .reports .mob-top-margin {
    margin-top: 12px;
  }
}
.reports .header {
  color: #525252;
}
.reports .select-container {
  display: flex;
}
.reports .select-container .org-select {
  width: 90%;
}
@media only screen and (max-width: 576px) {
  .reports .select-container .org-select {
    width: 100%;
  }
}
.reports .select-container .venue-select {
  width: 90%;
}
@media only screen and (max-width: 576px) {
  .reports .select-container .venue-select {
    margin-top: 12px;
    width: 100%;
  }
}
.reports .select-container .date-select {
  margin-left: auto;
  margin-top: auto;
}
.reports .select-container .date-select .date-container-btns {
  width: fit-content;
  margin-left: auto;
}
.reports .select-container .date-select .date-container-btns button:first-child {
  margin-left: 0px;
}
.reports .select-container .date-select .date-container-btns button {
  margin-left: 8px;
}
@media only screen and (max-width: 576px) {
  .reports .select-container .date-select .date-container-btns {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 576px) {
  .reports .select-container .date-select {
    margin-left: 0px;
    margin-top: 12px;
  }
}
.reports .main-card {
  margin-top: 24px;
}
.small-modal-warnings .ant-modal-confirm-btns {
  width: 100%;
  margin: 24px 0px 0px 0px;
  display: flex;
}
.small-modal-warnings .ant-modal-confirm-btns button {
  margin: 0 auto;
  width: 100px;
}
.small-modal-warnings .ant-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
