.payment-history__history-info {
  padding: 10px 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
.payment-history__history-info:first-child {
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.payment-history__colum-left {
  width: 100%;
  display: block;
}
.payment-history__colum-right {
  float: right;
  text-align: right;
}
.payment-history__title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
}
.payment-history__amount {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.payment-history__text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 4px;
}
.payment-history__accurate-time {
  display: inline-block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}
.payment-history__card-info {
  font-size: 13px;
  line-height: 2.15;
  color: rgba(0, 0, 0, 0.54);
}
