.recharts-legend-wrapper {
  max-height: 100%;
  overflow: overlay;
}
.usageElectronWrap {
  display: flex;
  flex-direction: row;
}
.widget {
  width: 100%;
}
.widget .widget-body {
  padding: 30px 25px 35px;
  height: 142px;
  -webkit-border-radius: 5px;
  border-radius: 0;
  overflow: hidden;
  color: #ffffff;
  margin-bottom: 16px;
  background-color: #acb7bf;
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .widget .widget-body {
    padding: 15px 15px 25px;
    height: 90px;
  }
}
.widget .widget-body.success {
  background-color: #46be8a;
  color: #ffffff;
}
.widget .widget-body.white {
  background-color: #ffffff;
  color: #827ca1;
}
.widget .widget-body-inner {
  position: relative;
  z-index: 2;
  height: 100%;
}
.widget-title {
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: inherit;
}
@media only screen and (max-width: 768px) {
  .widget-title {
    font-size: 13px;
    white-space: pre-wrap;
  }
}
@media only screen and (max-width: 576px) {
  .widget-title {
    font-size: 12px;
  }
}
@media only screen and (max-width: 360px) {
  .widget-title {
    font-size: 11px;
  }
}
.widget-img-icon {
  position: absolute;
  left: 0px;
  top: 38px;
  bottom: 0px;
  color: inherit;
  max-height: 50px;
}
@media only screen and (max-width: 768px) {
  .widget-img-icon {
    max-height: 36px;
    top: 30px;
  }
}
@media only screen and (max-width: 360px) {
  .widget-img-icon {
    max-height: 33px;
    margin-top: 6px;
  }
}
.widget-icon {
  position: absolute;
  left: 0px;
  top: 38px;
  bottom: 0px;
  color: inherit;
  font-size: 45px;
}
.widget-icon-shop {
  font-size: 35px;
}
@media only screen and (max-width: 768px) {
  .widget-icon-shop {
    font-size: 30px;
  }
}
@media only screen and (max-width: 360px) {
  .widget-icon-shop {
    font-size: 28px;
    margin-top: 8px;
  }
}
.widget-count {
  position: absolute;
  bottom: -15px;
  right: 5px;
  font-size: 42px;
  color: inherit;
  font-weight: 500;
}
@media only screen and (max-width: 1024px) {
  .widget-count {
    font-size: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .widget-count {
    font-size: 26px;
    bottom: -20px;
  }
}
@media only screen and (max-width: 360px) {
  .widget-count {
    font-size: 20px;
  }
}
.widget-count-duration {
  white-space: nowrap;
}
@media only screen and (max-width: 992px) {
  .widget-count-duration {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .widget-count-duration {
    font-size: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .widget-count-duration {
    font-size: 14px;
  }
}
@media only screen and (max-width: 360px) {
  .widget-count-duration {
    font-size: 12.5px;
  }
}
