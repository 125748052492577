.milestones-card {
  width: 100%;
}
.milestones-card .ant-card-head,
.milestones-card .ant-card-body {
  padding-left: 6px !important;
}
.milestones-card .anticon {
  color: #5856d6 !important;
}
.milestones-card .ant-statistic-content-suffix {
  color: #797979 !important;
  font-size: 18px;
}
