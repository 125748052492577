.smsSender .ant-modal-body {
  padding: 0;
}
.smsSender .ant-card-bordered {
  border: 0;
}
.smsSenderTemplate {
  white-space: normal;
  height: auto!important;
}
