.time-block__container {
  width: 448px;
}
.time-block__input-group {
  padding-bottom: 16px;
  border-bottom: solid 1px #e6e6e6;
  margin-bottom: 12px;
  position: relative;
}
.time-block__input-group--column-group {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 24px;
}
.time-block__title-column:before,
.time-block__title-column:after {
  display: table;
  content: " ";
}
.time-block__title-column:after {
  clear: both;
}
.time-block__title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: #666666;
  margin-bottom: 5px;
}
.time-block__title--column-left {
  float: left;
}
.time-block__delete,
.time-block__clearBreak {
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: right;
  color: #f21818;
  margin-bottom: 5px;
  padding: 0;
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.time-block__breakenabler {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -10;
}
.time-block__week-group {
  position: relative;
  margin-bottom: 16px;
}
.time-block__week-group:before,
.time-block__week-group:after {
  display: table;
  content: " ";
}
.time-block__week-group:after {
  clear: both;
}
.time-block__label-days {
  display: block;
  float: left;
  width: 64px;
  height: 40px;
  padding: 7px 0;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #222222;
  border: solid 1px #e6e6e6;
  border-left: 0;
  background-color: #f6f6f6;
  cursor: pointer;
}
.time-block__label-days.sunday {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-left: solid 1px #e6e6e6;
}
.time-block__label-days.saturday {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.time-block__checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}
.time-block__checkbox:checked + .time-block__label-days {
  font-weight: bold;
  line-height: 1.5;
  color: #ffffff;
  background-color: #2e8ae6;
  border: solid 1px #246bb3;
}
.time-block__checkbox:checked + .time-block__label-days.sunday {
  border-left: solid 1px #246bb3;
}
.time-block__checkbox[disabled] + .time-block__label-days {
  opacity: 0.5;
}
.time-block__addBreak {
  display: block;
  width: 100%;
  line-height: 40px;
  background-color: #dcdcdc;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}
.time-block__breakenabler:not(:checked) + .breakTimeRange {
  display: none;
}
.time-block__breakenabler:checked + .breakTimeRange + .breakTimeAdd {
  display: none;
}
.time-block__input-wrapper {
  float: none;
  display: block;
}
.time-block__input-wrapper:before,
.time-block__input-wrapper:after {
  display: table;
  content: " ";
}
.time-block__input-wrapper:after {
  clear: both;
}
.time-block__input-group-hours:before,
.time-block__input-group-hours:after {
  display: table;
  content: " ";
}
.time-block__input-group-hours:after {
  clear: both;
}
.time-block__column {
  display: block;
  float: left;
  width: 212px;
  margin-right: 24px;
  position: relative;
}
.time-block__column:last-child {
  margin-right: 0;
}
.time-block__input {
  display: block;
  float: left;
  width: 106px;
  padding: 7px 0;
  border: 0;
  background-color: #f6f6f6;
  border: solid 1px #e6e6e6;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #222222;
  cursor: pointer;
}
.time-block__input:first-child {
  border-right: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.time-block__input:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.time-block__btn-add {
  display: block;
  width: 100%;
  padding: 10px 0;
  border: 0;
  border-radius: 6px;
  background-color: #666666;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: all ease-out 0.25s;
}
.time-block__btn-add:hover {
  background-color: #737373;
}
.time-block__btn-add.error {
  background: red;
}
.time-block__btn-add.error:hover {
  background: red;
}
.ui-timepicker-wrapper {
  overflow-y: auto;
  max-height: 150px;
  width: 6.5em;
  background: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  outline: none;
  z-index: 10052;
  margin: 0;
}
.ui-timepicker-wrapper.ui-timepicker-with-duration {
  width: 13em;
}
.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-30,
.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-60 {
  width: 11em;
}
.ui-timepicker-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ui-timepicker-duration {
  margin-left: 5px;
  color: #888;
}
.ui-timepicker-list:hover .ui-timepicker-duration {
  color: #888;
}
.ui-timepicker-list li {
  padding: 3px 0 3px 5px;
  cursor: pointer;
  white-space: nowrap;
  color: #000;
  list-style: none;
  margin: 0;
}
.ui-timepicker-list:hover .ui-timepicker-selected {
  background: #fff;
  color: #000;
}
li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover {
  background: #1980EC;
  color: #fff;
}
li.ui-timepicker-selected .ui-timepicker-duration,
.ui-timepicker-list li:hover .ui-timepicker-duration {
  color: #ccc;
}
.ui-timepicker-list li.ui-timepicker-disabled,
.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  color: #888;
  cursor: default;
}
.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  background: #f2f2f2;
}
